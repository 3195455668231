.resend {
  &-container {
    background: white;
    opacity: 1 !important;
    color: black;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: left;
  }
}
